@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@layer tailwind-base, antd;

@layer tailwind-base {
  @tailwind base;
}
@tailwind components;
@tailwind utilities;

body {
  font-family: "Nunito Sans", sans-serif !important;
}

.ant-form-item-margin-offset {
  margin-bottom: 0 !important;
}
.ant-btn {
  min-height: 40px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #1677ff;
  border-radius: 4px;
}
